import templateUrl from './project-browse-project.html'
import './project-browse-project.scss'

import { SubmissionConfig } from '@/app/components/project-submission'

const ProjectBrowseProjectComponent = {
  bindings: {
    project: '<'
  },
  templateUrl,
  controller: class ProjectBrowseProject {
    constructor (UserService, PusherService, $filter, $stateParams, Storage) {
      'ngInject'
      this._identify = 'ProjectBrowseProjectComponent'
      this.UserService = UserService
      this.PusherService = PusherService
      this.$filter = $filter
      this.$stateParams = $stateParams
      this.Storage = Storage
    }

    $onInit () {

    }

    get projectTypeAndSubject () {
      let submissionFormId = 'codeable'
      let prefix = ''
      const unknownText = 'Project type not set'

      if (this.project.submissionFormVersion === '2.0') {
        submissionFormId = 'codeableVariant'
      }

      if (this.project.referralBadgeName && SubmissionConfig[this.project.referralBadgeName]) {
        submissionFormId = this.project.referralBadgeName
      }

      // Find project type
      let config = SubmissionConfig[submissionFormId]
      let typeConfig = null

      if (config) {
        typeConfig = config.types.find(type => type.project_type === this.project.projectType)

        // If type not found, try to fallback if possible to native form config / variant (because of overleveraging of referral badge name for referrals and partner forms dont cover all cases)
        if (!typeConfig) {
          // Try to fallback to native form or its variant
          submissionFormId = this.project.submissionFormVersion === '2.0' ? 'codeableVariant' : 'codeable'
          config = SubmissionConfig[submissionFormId]

          if (config) {
            typeConfig = config.types.find(type => type.project_type === this.project.projectType)
          }
        }
      }

      // Handle unknown config
      if (!config) {
        return `${prefix}${unknownText}`
      }

      // Handle unknown type
      if (!typeConfig) {
        return `${prefix}${unknownText}`
      }

      const subjectConfig = typeConfig.options.find(subject => subject.value === this.project.projectSubject)

      // Handle consultations and project types wihtout subject
      if (typeConfig.project_type === 'consultation' || !subjectConfig) {
        return `${prefix}${typeConfig.title}`
      }

      // Handle codeable variant sentecing of type and project
      if (submissionFormId === 'codeableVariant' || submissionFormId === 'codeable') {
        return `${prefix}${typeConfig.title} ${this.$filter('submissionStepHeaderSubjectFilter')(subjectConfig)}`
      }

      // Handle other combinations
      return `${prefix}${typeConfig.title} | ${subjectConfig.name}`
    }

    get referalName () {
      let submissionFormId = 'codeable'
      let prefix = ''

      if (this.project.referralBadgeName && SubmissionConfig[this.project.referralBadgeName]) {
        submissionFormId = this.project.referralBadgeName
      }

      // Find project type
      let config = SubmissionConfig[submissionFormId]

      // Handle referral partner name (make sure to not use fallback config)
      if (this.project.referralBadgeName &&
          SubmissionConfig[this.project.referralBadgeName] &&
          SubmissionConfig[this.project.referralBadgeName].displayName) {
        prefix = `${config.displayName}`
      } else if (this.project.referralBadgeName) {
        prefix = `${this.project.referralBadgeName}`
      }

      return prefix
    }

    get projectDate () {
      if (this.project.postedDate && ['preferred', 'expert-pods'].includes(this.$stateParams.listId)) {
        return this.project.postedDate
      }

      return this.project.publishedDate
    }

    get projectBudget () {
      if (this.project.submissionFormVersion >= '2') {
        return this.$filter('budgetRangeFormat')(this.project.budgetRange)
      }

      return this.$filter('budgetDisplay')(this.project.budget)
    }

    get showPreferredDate () {
      return this.project.postedDate && this.project.publishedDate && this.$stateParams.listId === 'preferred'
    }


    get relativePreferredDate () {
      if (!this.project.publishedDate) {
        return 'Stays preferred'
      }

      // TODO: check for user timezoneoffset to be included
      const hourDiff = this.$filter('dateDifference')(this.project.publishedDate, 'now', 'seconds')
      if (hourDiff <= 0) {
        // TODO: check for user timezoneoffset to be included
        return this.$filter('dateFromRelative')(this.project.publishedDate, 'now')
      }

      // time difference is greated than 24h so project is already published for all
      return 'Published for all'
    }

    get isClientOnline () {
      if (this.project.client) {
        return this.PusherService.isUserOnline(this.project.client.id)
      }
      return false
    }

    get showDebug () {
      // Add 'ngStorage-debug--project-browse' to localstorage
      return this.Storage.get('debug--project-browse') || false
    }
  }
}

export default ProjectBrowseProjectComponent
