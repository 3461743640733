import WoocommerceSubmissionConfig from './submission.config.v1.woocommerce'
import SitegroundrcSubmissionConfig from './submission.config.v1.sitegroundrc'
import Barn2ukSubmissionConfig from './submission.config.v1.barn2uk'
import CodeableSubmissionConfig from './submission.config.v1.codeable'
import CodeableVariantSubmissionConfig from './submission.config.v2.codeable.variant'

export const SubmissionConfig = {
  woocommerce: WoocommerceSubmissionConfig,
  sitegroundrc: SitegroundrcSubmissionConfig,
  barn2uk: Barn2ukSubmissionConfig,
  codeableVariant: CodeableVariantSubmissionConfig,
  codeable: CodeableSubmissionConfig
}
