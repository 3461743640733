import WorkroomComponent from './workroom.component'
import WorkroomService from './workroom.service'

import TaskExpertFeedbackModule from './task-expert-feedback'
import ActivityStreamModule from './activity-stream'
import WorkroomProjectInfoModule from './workroom-project-info'
import ProjectSubscribeToNotificationsModule from './project-subscribe-to-notifications'
import ProjectUserCardModule from './project-user-card'
import ProjectTeamModule from './project-team'
import ProjectExpertPodsModule from './project-expert-pods'
import ProjectScopeDocModule from './project-scope-doc'
import ProjectReviewActionsModule from './project-review-actions'

import AdditionalTasksModule from './additional-tasks'
import ProjectSubmissionMetaModule from './submission-meta-modal'

import ProjectClaimsSidebarWidgetComponent from './project-claims/sidebar-widget'


const WorkroomModule = angular.module('cdbl.workroom', [
  TaskExpertFeedbackModule,
  ActivityStreamModule,
  WorkroomProjectInfoModule,
  ProjectSubscribeToNotificationsModule,
  AdditionalTasksModule,
  ProjectTeamModule,
  ProjectExpertPodsModule,
  ProjectUserCardModule,
  ProjectScopeDocModule,
  ProjectReviewActionsModule,
  ProjectSubmissionMetaModule,
  ProjectClaimsSidebarWidgetComponent,
])
  .service('WorkroomService', WorkroomService)
  .component('cdblWorkroom', WorkroomComponent)
  .name

export default WorkroomModule
